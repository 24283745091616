var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.ready)?_c('v-card',{attrs:{"elevation":"4"}},[(false && _vm.mappedMenuItemsFilter.length)?_c('v-card-title',{class:_vm.appSettings.headingTextColor},[_vm._v("has items")]):_vm._e(),_c('v-list',{class:_vm.$vuetify.breakpoint.mobile ? 'custScroller' : 'custScroller',staticStyle:{"overflow-y":"auto"},attrs:{"dense":"","max-height":_vm.$vuetify.breakpoint.mobile ? '230px' : '310px'}},[_vm._l((_vm.mappedMenuItemsFilter),function(item,itemIndex){return [_c('v-list-item',{key:item.index,on:{"click":function($event){_vm.loadMarkerByIdFromMenu(item.index);
          _vm.popupClicked(item);}}},[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"grey--text font-weight-normal"},[_c('div',{staticClass:"pa-0  rounded-circle d-inline-block",style:({
                height: '12px',
                width: '12px',
                background:
                  item.section_slug && _vm.categoryLookup[item.section_slug]
                    ? _vm.categoryLookup[item.section_slug].color
                    : '',
                border: '1px solid grey'
              })}),_vm._v("      "),_c('font',{directives:[{name:"show",rawName:"v-show",value:(
                _vm.appSettings.showSiteNumbers && item.Site_Marker_Number_Label
              ),expression:"\n                appSettings.showSiteNumbers && item.Site_Marker_Number_Label\n              "}]},[_vm._v(_vm._s(item.Site_Marker_Number_Label)+" - ")]),_vm._v(" "+_vm._s(item.Point_Name)),(_vm.categoryLookup[item.section_slug])?_c('font',[_vm._v(" | "+_vm._s(_vm.categoryLookup[item.section_slug].title))]):_vm._e()],1)],1)],1),(_vm.mappedMenuItemsFilter.length - 1 != itemIndex)?_c('v-divider',{key:item.header}):_vm._e()]})],2)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }