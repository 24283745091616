import Vue from "vue";
import App from "./App.vue";
//import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import VueScrollTo from "vue-scrollto";
import VueYouTubeEmbed from "vue-youtube-embed";
import browserDetect from "vue-browser-detect-plugin";

const eventBusItems = {
  nav: [
    "nav_sideBar_openSiteList",
    "nav_sideBar_openMoreInfo",
    "nav_layout_openMenu",
    "nav_layout_closeMenu",
    "nav_map_flyToOpenPopUp",
    "nav_map_reset",
    "nav_siteList_scrollToTop"
  ]
};

if (eventBusItems) {
  //do nothing yet
}

//Logging
//import * as Sentry from "@sentry/vue";
//import { Integrations } from "@sentry/tracing";

import VueJsonPretty from "vue-json-pretty";
import "vue-json-pretty/lib/styles.css";
import VueGtag from "vue-gtag";
//import VueAnalytics from "vue-analytics";

Vue.config.productionTip = false;

Vue.use(VueYouTubeEmbed);
Vue.use(browserDetect);

Vue.use(VueScrollTo, {
  //container: ".tabcontent",
  container: ".siteListWrapper",
  duration: 500,
  easing: "ease",
  offset: 0,
  force: true,
  cancelable: true,
  onStart: false,
  onDone: false,
  onCancel: false,
  x: false,
  y: true
});

/*
Sentry.init({
  Vue,
  dsn:
    "https://6333efb4289a42afbf12426d27a63e3b@o522405.ingest.sentry.io/5633819",
  integrations: [new Integrations.BrowserTracing()],

  // We recommend adjusting this value in production, or using tracesSampler
  // for finer control
  tracesSampleRate: 1.0,
  logErrors: true
});
*/
Vue.component("vue-json-pretty", VueJsonPretty);

Vue.use(VueGtag, {
  config: { id: "G-FBG26QL0SC" } //sovereignhill prod G-FBG26QL0SC;
});

export const eventBus = new Vue();

new Vue({
  //router,
  store,
  vuetify,

  render: h => h(App)
}).$mount("#app");
