<template>
  <div>
    <v-toolbar :color="appSettings.menuBarColor">
      <v-tabs v-model="tab" color="primary darken-4 ">
        <v-tabs-slider color="primary"></v-tabs-slider>

        <v-tab
          class="headingText"
          v-for="tabItem in tabList"
          :key="tabItem"
          :disabled="
            tabItem == 'More Info' && !sidebarSite && sidebarSite !== 0
              ? true
              : false
          "
        >
          {{ tabItem }}
        </v-tab>
      </v-tabs>

      <v-btn
        class="mvHover"
        large
        icon
        tile
        @click="resetMap()"
        title="Reset Interface"
      >
        <v-icon medium color="">mdi-restart</v-icon>
      </v-btn>
    </v-toolbar>

    <v-tabs-items v-model="tab" class="tabcontent">
      <v-tab-item>
        <div class="siteListWrapper">
          <v-card flat>
            <SiteList :isOpen="tab === 0" :parentDrawer="parentDrawer" />
          </v-card>
        </div>
      </v-tab-item>

      <v-tab-item>
        <SidebarSiteInfo />

        <v-card flat v-if="false">
          <v-card-text> sidebarSite:{{ sidebarSite }} </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import { eventBus } from "../main";

import { mapState } from "vuex";
import SiteList from "./../components/SiteList.vue";
import SidebarSiteInfo from "./../components/info/SidebarSiteInfo.vue";

export default {
  props: {
    isOpen: Boolean,
    parentDrawer: Boolean
  },
  components: {
    SiteList,
    SidebarSiteInfo
  },
  name: "SideBar",
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      markerList: state => state.markers.markerList,
      sidebarSite: state => state.navigation.sidebarSite
    }),
    sites() {
      return this.markerList.data.features;
    }
  },
  data() {
    return {
      tab: 0,
      tabList: ["Sites", "More Info"]
    };
  },
  methods: {
    resetMap() {
      eventBus.$emit("nav_map_reset");
    }
  },
  created() {
    eventBus.$on("nav_sideBar_openSiteList", () => {
      this.tab = 0;
    });

    eventBus.$on("nav_sideBar_openMoreInfo", () => {
      this.tab = 1;
    });
  },

  watch: {}
};
</script>
<style>
.tabcontent {
  max-height: calc(100vh - 116px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

.siteListWrapper {
  max-height: calc(100vh - 118px);
  margin: 0;
  padding: 0;
  overflow-y: auto;
  overflow-x: hidden;
}

@media only screen and (max-width: 1263px) {
  .tabcontent {
    max-height: calc(100vh - 80px);
  }
  .siteListWrapper {
    max-height: calc(100vh - 82px);
  }
}
</style>
