<template>
  <div class="d-flex flex-row mt-6 red--text">
    <a
      v-if="!$vuetify.breakpoint.xsOnly"
      class="pr-2"
      @click="hhwModeValue = !hhwModeValue"
      :class="hhwModeValue != true ? 'black--text text--lighten-7' : ''"
    >
      Sovereign Hill Map</a
    >
    <v-switch v-model="hhwModeValue" inset></v-switch>

    <a
      v-if="!$vuetify.breakpoint.xsOnly"
      @click="hhwModeValue = !hhwModeValue"
      :class="hhwModeValue == true ? 'black--text text--lighten-7' : ''"
    >
      Heritage Harvest Weekend Map
    </a>
    <a
      v-if="$vuetify.breakpoint.xsOnly"
      @click="hhwModeValue = !hhwModeValue"
      :class="
        hhwModeValue == true
          ? 'black--text text--lighten-7 smallText '
          : 'smallText'
      "
    >
      Heritage<br />Harvest<br />Weekend
    </a>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {
      //hhwModeValue: false
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    }),

    hhwModeValue: {
      get() {
        return this.$store.state.filters.hhwModeValue;
      },
      set(value) {
        this.$store.dispatch("filters_changeMode", value);
        this.$store.dispatch("filters_resetLayers");
      }
    }
  },
  methods: {
    /*getUrl(url) {
      let videoId = getIdFromURL(url);
      return videoId;
    },*/
  },

  mounted() {},

  watch: {
    /*
    hhwModeValue: function(newValue) {
      console.log("newValue", newValue);
      this.$store.dispatch("filters_changeMode", newValue);
      this.$store.dispatch("filters_resetLayers");
    }
*/
  }
};
</script>
<style scoped>
.smallText {
  font-size: 0.7em !important;
  margin-top: -14px;
}
</style>
