<template>
  <div
    class="mvLegendWrapper"
    style="width:0;height:0;
    overflow: visible;"
    :class="drawer ? 'open' : 'close'"
  >
    <div class="mvLegend">
      <div class="mobileSet">
        <div class="mvLegendButtonWrapper" v-if="!showLegend">
          <v-btn
            title="Show Legend"
            z-depth="0"
            depressed
            notsmall
            class="ml-2 pa-0"
            :color="!showLegend ? appSettings.mediaButtonColor : 'white'"
            @click="showLegend = !showLegend"
          >
            <v-icon v-if="showLegend">mdi-close-circle </v-icon>
            <v-icon v-if="!showLegend">mdi-map-legend </v-icon>
          </v-btn>
        </div>

        <div
          v-show="showLegend"
          class="legendListActWrapper"
          style="border-radius: 8px;overflow: hidden;"
        >
          <v-card
            :max-width="$vuetify.breakpoint.smAndDown ? 250 : 250"
            class="mx-auto"
          >
            <v-toolbar color="" dense height="35">
              <v-toolbar-title>Legend</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-btn icon @click="showLegend = false" title="Close Legend">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-toolbar>

            <v-list dense class="legendListAct">
              <template v-for="(section, key, index) in dynamicLegned">
                <v-subheader
                  v-show="section.items.length"
                  class="text-subtitle-2 font-weight-bold ml-10 mb-1 pt-1"
                  :key="`legend_heading_${key}`"
                  v-text="section.title"
                ></v-subheader>

                <v-list-item
                  v-show="section.items.length"
                  v-for="(item, i) in section.items"
                  :key="`legend_list_${key}_item_${i}`"
                >
                  <v-list-item-avatar :tile="item.tile === true" size="26">
                    <template
                      v-if="
                        item &&
                          item.featured_image_obj &&
                          item.featured_image_obj.thumb
                      "
                    >
                      <img :src="`${item.featured_image_obj.thumb}`" />
                    </template>
                    <template v-else>
                      <v-avatar
                        size="22"
                        :color="item.color"
                        :tile="item.tile === true"
                        v-bind:style="item.style"
                      ></v-avatar>
                    </template>
                  </v-list-item-avatar>

                  <v-list-item-content>
                    <v-list-item-title
                      class="font-weight-regular"
                      v-text="item.name"
                    ></v-list-item-title>

                    <v-list-item-subtitle
                      v-if="false && item.description"
                      v-text="item.description"
                    ></v-list-item-subtitle>
                  </v-list-item-content>
                </v-list-item>

                <v-divider
                  v-show="
                    section.items.length && index != dynamicLegnedSections - 1
                  "
                  class="my-1"
                  :key="`legend_devider_${key}`"
                ></v-divider>
              </template>
            </v-list>
          </v-card>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import { eventBus } from "../../main";

import { mapState } from "vuex";
export default {
  name: "mapLegend",

  props: {
    drawer: Boolean
  },

  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings,
      categoryLookup: state => state.markers.categoryLookup,
      geoJson_signage: state => state.markers.geoJson_signage
    }),
    iconSize() {
      const size = { xs: "x-small", sm: "x-small", lg: "small", xl: "small" }[
        this.$vuetify.breakpoint.name
      ];
      return size ? { [size]: true } : {};
    },

    dynamicLegned() {
      if (this.geoJson_signage?.data?.features?.length) {
        let legned = {
          main_marker: {
            title: "Marker Layers",
            items: []
          },
          other: {
            title: "Other Layers",
            items: []
          }
        };

        for (const key in this.categoryLookup) {
          let row = JSON.parse(JSON.stringify(this.categoryLookup[key]));
          row.tile = true;
          if (row.section_type === "main_marker") {
            legned.main_marker.items.push(row);
          } else if (row.section_type != "artwork") {
            delete row.featured_image_obj;
            //legned.other.items.push(row);
            legned[key] = { title: row.title, items: [] };
          }
        }

        let lookup = {};
        this.geoJson_signage.data.features.forEach(row => {
          let tempRow = JSON.parse(JSON.stringify(row.properties));

          var title = tempRow.Point_Name;
          let words = title
            .replace("Ramp", "Ramp ")
            .replace("Evac", "Evacuation Point ")
            .trim()
            .split(" ");
          if (!isNaN(words[words.length - 1].trim())) {
            words.pop();
          }

          title = words.join(" ");
          lookup[title] = {
            featured_image_obj: tempRow.icon_obj,
            name: title,
            tile: true,
            section: tempRow.section_slug ? tempRow.section_slug : "other"
          };
        });

        _.values(lookup).forEach(row => {
          if (row.section) {
            legned[row.section].items.push(row);
          }
        });

        this.staticLegendItems.forEach(row => {
          legned[row.section].items.push(row);
        });

        return { ...legned, ...this.staticLegendSections };
      } else {
        return {};
      }
    },

    dynamicLegnedSections() {
      return _.size(this.dynamicLegned);
    }
  },
  data: () => ({
    staticLegendItems: [
      {
        name: "Accessible Paths",
        section: "base-map-emergency-info",
        tile: true,
        style: `
            height: 1px;
    width: 48px;
          background:none!important;
    border-color: rgb(89, 69, 69);
    border-style: dashed solid;
    border-width: 2px;
            `
      }
    ],
    staticLegendSections: {
      base: {
        title: "Map Features",
        items: [
          {
            name: "Road",
            color: "#c87727",
            tile: true
          },

          {
            name: "Buildings",
            color: "#e0d3aa",
            tile: true
          },
          {
            name: "Water",
            color: "#bfe0e4"
          },
          {
            name: "Parkland",
            color: "#9db493"
          }
        ]
      }
    },

    readOnlyButtonValues: [],

    showLegend: false
  }),
  methods: {
    remoteClose() {
      this.showLegend = false;
    }
  },
  created() {
    eventBus.$on("remote-ui-legned-close", this.remoteClose);
  },
  beforeDestroy() {
    eventBus.$off("remote-ui-legned-close", this.remoteClose);
  },

  watch: {
    showLegend: function(newValue) {
      if (newValue === true) {
        window.mvAnalyticEvent("ui", "mapLegendOpen", "open", false);

        eventBus.$emit("remote-ui-layercontrol-close");
      }
    }
  }
};
</script>

<style scoped>
.mobileSet {
  transition: all 0.5s;
  position: absolute;
  border-radius: 2px;
}

.mvLegendWrapper {
  z-index: 1000;
  overflow: visible;
}
.mvLegend {
  background: red;
  width: 0;
  height: 0;
  position: relative;
  bottom: 26px;
  right: 290px;

  transition: all 0.2s;
}
.mvLegendWrapper.open .mvLegend {
  right: 290px;
}

.mvLegendWrapper.close .mvLegend {
  right: -10px;

  bottom: 42px;
}

.legendListAct {
  max-height: calc(100vh - 200px);
  xxmax-height: calc(var(--doc-height) - 100px);
  overflow-y: auto;
}

.mvLegendButtonWrapper,
.legendListActWrapper {
  position: absolute;
  bottom: 0;
  right: 0;
}
.legendListActWrapper {
  border: 1px solid #d8d8d8;
  width: 250px;
}

.v-list-item__title,
.v-list-item__subtitle {
  flex: 1 1 100%;
  overflow: visible;
  text-overflow: unset;
  white-space: unset;
}

.v-list--dense .v-subheader {
  height: auto;
}

.v-list--dense .v-list-item {
  min-height: 25px;
}

.v-list--dense .v-list-item .v-list-item__content {
  padding: 4px 0;
}
.v-list--dense .v-list-item__avatar {
  margin-bottom: 0px;
  margin-top: 1px;
}

@media only screen and (max-width: 959px) {
  div.mvLegendWrapper.open div.mvLegend,
  div.mvLegendWrapper.close div.mvLegend {
    right: -10px;

    bottom: 22px;
  }
}

@media only screen and (max-height: 750px) {
  .legendListAct {
    max-height: calc(530px);
  }
}
@media only screen and (max-height: 700px) {
  .legendListAct {
    max-height: calc(480px);
  }
}
@media only screen and (max-height: 650px) {
  .legendListAct {
    max-height: calc(430px);
  }
}
@media only screen and (max-height: 600px) {
  .legendListAct {
    max-height: calc(380px);
  }
}
@media only screen and (max-height: 550px) {
  .legendListAct {
    max-height: calc(330px);
  }
}
@media only screen and (max-height: 500px) {
  .legendListAct {
    max-height: calc(280px);
  }
}
@media only screen and (max-height: 450px) {
  .legendListAct {
    max-height: calc(230px);
  }
}
@media only screen and (max-height: 400px) {
  .legendListAct {
    max-height: calc(180px);
  }
}
@media only screen and (max-height: 350px) {
  .legendListAct {
    max-height: calc(130px);
  }
}
@media only screen and (max-height: 300px) {
  .legendListAct {
    max-height: calc(90px);
  }
}
@media only screen and (max-height: 250px) {
  .legendListAct {
    max-height: calc(50px);
  }
}
@media only screen and (max-height: 200px) {
  .legendListAct {
    max-height: calc(50px);
  }
}
</style>
