var axios = require("axios").default;

if (!axios) {
  axios = require("axios");
}

const genGeoJson_library = require("./genGeoJson_library");

const config = require("./config");

const wpUrl = "https://sh-dev.maptivateapp.com.au/wp/";

const targets = config.targets;

//import { proxyRefs } from "vue";

//import { _ } from "core-js";

//const useLocal = false;

Array.prototype.forEachAsync = async function(fn) {
  for (let t of this) {
    await fn(t);
  }
};

Array.prototype.forEachAsyncParallel = async function(fn) {
  await Promise.all(this.map(fn));
};

const cycleThroughPosts = async function(axios, wpUrl, postType) {
  var allResults = [];

  let page = 1;
  let hasError = false;
  let wpTotalpages = 100;

  let lastResult;
  do {
    let results = await axios.get(
      `${wpUrl}/wp-json/wp/v2/${postType.postId}/?per_page=99&page=${page}`
    );
    if (results.status !== 200) {
      hasError = true;
      break;
    }
    if (!(results?.headers && results.headers["x-wp-totalpages"])) {
      hasError = true;
      break;
    }
    if (results.data && results.data.length) {
      allResults = [...allResults, ...results.data];
    }
    wpTotalpages = results.headers["x-wp-totalpages"];
    page++;
    lastResult = results;
  } while (page <= wpTotalpages && !hasError);
  lastResult.data = allResults;
  return lastResult;
};

const _getData = async function() {
  let output;

  var post = {};
  var taxonomy = {};

  await targets.forEachAsyncParallel(async postType => {
    try {
      //let results = await vm.axios.get(`${wpUrl}/wp-json/wp/v2/${postType.postId}/?per_page=10`);

      let results = await cycleThroughPosts(axios, wpUrl, postType);
      if (results && results.data) post[postType.postId] = results.data;
    } catch (e) {
      //console.error("errorA", e);
    }

    if (postType.taxonomies && postType.taxonomies.length) {
      await postType.taxonomies.forEachAsyncParallel(async taxType => {
        try {
          let results = await axios.get(
            `${wpUrl}/wp-json/wp/v2/${taxType}?per_page=99`
          );
          if (results && results.data) taxonomy[taxType] = results.data;
        } catch (e) {
          //console.error("errorB", e);
        }
      });
    }
  });

  output = await genGeoJson_library.genGeoJsonAndConfig(
    { post, taxonomy },
    targets
  );

  return output;
};

module.exports.getData = _getData;
