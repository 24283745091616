<template>
  <div class="siteKeyAct_wrapper  pb-10 pb-12">
    <v-list two-line class="ma-0 pa-0" v-if="true">
      <v-list-item @click.prevent="loadIntro()">
        <v-list-item-avatar size="45">
          <v-img src="assets/landingPages/acknowledgement.jpeg"></v-img>
        </v-list-item-avatar>

        <v-list-item-content>
          <v-list-item-subtitle
            class="font-weight-bold text-subtitle-2"
            v-html="'Acknowledgement of Country'"
          ></v-list-item-subtitle>
          <v-list-item-subtitle
            v-if="false"
            v-html="'Welcome'"
          ></v-list-item-subtitle>
        </v-list-item-content>
      </v-list-item>
    </v-list>

    <center v-if="!showMarkerLayer.length">
      <br />
      <div class="ml-4 heading right">... no items</div>
    </center>

    <v-btn
      v-if="!showMarkerLayer.length"
      depressed
      :color="appSettings.mediaButtonColor"
      :width="appSettings.drawButtonWidth"
      class="ma-2 white--text"
      @click="resetFilters()"
    >
      <v-icon left>mdi-cached</v-icon>

      Reset filters
    </v-btn>

    <div style="display:none;;">
      sidebarSite:{{ sidebarSite }};; sidebarSiteAct:{{ sidebarSiteAct }}
    </div>

    <v-list class="ma-0 pa-0 ">
      <v-list-item-group
        class="ma-0 pa-0"
        active-class="error--text"
        v-model="sidebarSiteAct"
      >
        <template v-for="(site, index) in sites">
          <div
            v-bind:key="'heading_' + site.properties.index"
            v-if="
              layers.includes(site.properties.section_slug) &&
                (!sites[index - 1] ||
                  (sites[index - 1] &&
                    sites[index - 1].properties.section_slug !=
                      sites[index].properties.section_slug))
            "
          >
            <v-divider class="mt-3 mb-2"></v-divider>

            <div class="ml-4 heading" style="height:65px">
              <v-avatar class="profile" size="60" tile style="float:left">
                <img
                  v-show="
                    site.properties &&
                      site.properties.section_slug &&
                      categoryLookupExtra[site.properties.section_slug] &&
                      categoryLookupExtra[site.properties.section_slug]
                        .list_image_obj &&
                      categoryLookupExtra[site.properties.section_slug]
                        .list_image_obj.thumb
                  "
                  :src="
                    `${
                      categoryLookupExtra[site.properties.section_slug]
                        .list_image_obj.thumb
                    }`
                  "
              /></v-avatar>

              <div
                style="height:60px;padding-left:62px;"
                class="ml-2"
                :style="{
                  'padding-top':
                    categoryLookupExtra[site.properties.section_slug].padding
                }"
              >
                {{ categoryLookupExtra[site.properties.section_slug].title }}
              </div>
            </div>
            <v-divider style="opacity: .6;" class="mt-1"></v-divider>
          </div>

          <v-list-item
            v-if="layers.includes(site.properties.section_slug)"
            v-bind:key="site.properties.index"
            v-bind:class="[
              'siteKeyAct_' + site.properties.index,
              sidebarSite === site.properties.index ? 'currentlyOpen' : ''
            ]"
            @click.prevent="focusOnSite(site.properties.index)"
            @mouseenter="hoverOnListItem(site.properties.index)"
            v-on:dblclick="loadMoreInfo(site.properties.index)"
          >
            <v-list-item-avatar
              v-if="site.properties.theme === 'hhw'"
              :class="`icon theme_${site.properties.theme}`"
              color="#5c6b64"
              size="40"
            >
              <div
                class="white--text avatarSiteNumber"
                v-show="appSettings.showSiteNumbers"
              >
                {{ site.properties.Site_Marker_Number_Label }}
              </div>
            </v-list-item-avatar>

            <v-list-item-avatar
              v-if="site.properties.theme != 'hhw'"
              tile
              :class="`icon theme_${site.properties.theme}`"
            >
              <img
                v-if="site.properties && site.properties.icon_id"
                :src="`${markerImages[site.properties.icon_id].thumb}`"
              />

              <div
                class="white--text avatarSiteNumber"
                v-show="appSettings.showSiteNumbers"
              >
                {{ site.properties.Site_Marker_Number_Label }}
              </div>
            </v-list-item-avatar>

            <v-list-item-content>
              <v-list-item-title
                v-text="site.properties.Point_Name"
              ></v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
import { mapState } from "vuex";
import { eventBus } from "../main";

export default {
  name: "SiteList",
  props: {
    isOpen: Boolean,
    parentDrawer: Boolean
  },
  computed: {
    ...mapState({
      sidebarSite: state => state.navigation.sidebarSite,
      markerList: state => state.markers.markerList,
      categoryLookup: state => state.markers.categoryLookup,
      markerImages: state => state.markers.markerImages,
      baseurllocation: state => state.navigation.baseurllocation,
      appSettings: state => state.global.appSettings,
      layers: state => state.filters.layers
      //defaultLayers: state => state.filters.defaultLayers
    }),
    categoryLookupExtra() {
      let value = JSON.parse(JSON.stringify(this.categoryLookup));

      for (const key in value) {
        let obj = value[key];
        if (!obj.list_image_obj) {
          value[key].list_image_obj = obj.featured_image_obj;
        }
      }

      return value;
    },
    sites() {
      return this.markerList.data.features;
    },
    origin() {
      return window.location.origin;
    },
    showMarkerLayer() {
      let layers = [];
      for (const key in this.categoryLookup) {
        const row = this.categoryLookup[key];
        //is main marker and is visiable
        if (row.section_type === "main_marker" && this.layers.includes(key)) {
          layers.push(key);
        }
      }
      return layers;
    }
  },
  data: () => ({
    cancelScroll: null,
    sidebarSiteAct: false,
    holdScroll: false
    //oldSlectedSite: false
  }),
  methods: {
    hoverOnListItem(index) {
      eventBus.$emit("markerEffects_bouncMarker", index);
    },

    resetFilters() {
      //this.$store.dispatch("filters_controlsUpdateLayers", this.defaultLayers);
      this.$store.dispatch("filters_resetLayers");
    },
    getColor(theme) {
      let output = "red";
      switch (theme) {
        case "Prospective Families":
          output = this.appSettings.styles.markerColours[3];
          break;
        case "Wayfinding":
          output = this.appSettings.styles.markerColours[1];
          break;
        case "History":
          output = this.appSettings.styles.markerColours[2];
          break;
      }
      return output;
    },
    loadIntro() {
      eventBus.$emit("loadIntroPopup");
    },
    loadMoreInfo(index) {
      this.$store.dispatch("navUpdateSidebarSite", index);
    },
    focusOnSite(index) {
      eventBus.$emit("nav_map_flyToOpenPopUp", index);
      setTimeout(() => {
        this.holdScroll = false;
      }, 200);
      try {
        if (this.sites[index]) {
          try {
            //window.gtag("event","sbClick",this.sites[index].properties.Site_Marker_Number);
            window.mvAnalyticEvent(
              "ui",
              "sbClick",
              this.sites[index].properties.Site_Marker_Number,
              false
            );
          } catch (error) {
            //do nothing
          }

          let coordinates;
          coordinates = this.sites[index].geometry.coordinates
            ? this.sites[index].geometry.coordinates
            : this.sites[index].properties.Longitude &&
              this.sites[index].properties.Latitude
            ? [
                this.sites[index].properties.Longitude,
                this.sites[index].properties.Latitude
              ]
            : false;

          if (coordinates) {
            this.$store.dispatch("navUpdateFocusSite", index);
          }
        }
      } catch (error) {
        //do nothing
      }
    },
    scrollToSite(newValue) {
      if (!this.holdScroll && newValue != "false" && newValue != false) {
        let element = ".siteKeyAct_" + newValue;
        this.cancelScroll = this.$scrollTo(element);
      }
    },
    scrollToTopIfNull() {
      setTimeout(() => {
        if (this.sidebarSite == false) {
          setTimeout(() => {
            if (this.sidebarSite == false) {
              setTimeout(() => {
                if (this.sidebarSite == false) {
                  this.scrollToSite("wrapper");
                }
              }, 50);
            }
          }, 50);
        }
      }, 50);
    }
  },
  created() {
    eventBus.$on("nav_siteList_scrollToTop", () => {
      this.scrollToSite("wrapper");
    });
  },
  watch: {
    parentDrawer(newValue) {
      if (newValue === true && this.sidebarSite == false) {
        this.scrollToTopIfNull();
      }
    },
    isOpen(newValue) {
      if (newValue === true) {
        if (this.sidebarSite == false) {
          this.scrollToTopIfNull();
        } else {
          this.scrollToSite(this.sidebarSiteAct);
        }
      }
    },
    sidebarSite(newValue /* oldValue*/) {
      this.sidebarSiteAct = newValue;

      if (newValue) {
        this.scrollToSite(newValue);
      } else {
        this.scrollToTopIfNull();
      }
    }
  },

  async mounted() {}
};
</script>
<style>
.currentlyOpen {
}

.theme--light.v-list-item:not(.v-list-item--active):not(.v-list-item--disabled) {
  color: #546b66 !important;
}

.theme--light.v-list-item--active:hover::before,
.theme--light.v-list-item--active::before {
  opacity: 0.3;
}

.avatarSiteNumber {
  position: absolute;
  left: 0;
  top: 8px;
  width: 100%;
  //text-shadow: -1px -1px 0 rgba(0, 0, 0, 0.26), 1px -1px 0 rgba(0, 0, 0, 0.26), -1px 1px 0 rgba(0, 0, 0, 0.26), 1px 1px 0 rgba(0, 0, 0, 0.26);
}

.theme_hhw .white--text.avatarSiteNumber {
  xcolor: rgb(103 119 83) !important;
  xcaret-color: rgb(103 119 83) !important;
  xfont-weight: bold;
}
</style>
