/* eslint-disable */
<template>
  <div class="d-flex flex-row">
    <div class="text-center">
      <v-menu offset-y v-model="isOpen">
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="secondary" dark v-bind="attrs" v-on="on">
            Language

            <v-icon>
              mdi-menu-down
            </v-icon>
          </v-btn>
        </template>
        <v-list class="menuList">
          <template
            v-for="country in !showFullListAct ? countries : defaultCountries"
          >
            <v-list-item
              :key="country.title"
              @click="doTranslation(country.code)"
              :title="country.title"
            >
              <v-list-item-title>
                <font translate="no" class="notranslate">
                  {{ country.title }}
                </font>
                <font
                  v-if="
                    getNativeName(country) &&
                      country.title == 'English' &&
                      currentCountryNotEnglish
                  "
                >
                  - {{ getNativeName(country) }}
                </font>

                <font
                  v-if="getNativeName(country) && country.title != 'English'"
                  translate="no"
                  class="notranslate"
                >
                  - {{ getNativeName(country) }}
                </font>
              </v-list-item-title>
            </v-list-item>
          </template>

          <v-list-item @click="showMoreItems()" v-if="!showFullListAct">
            <v-list-item-title>
              <font translate="no" class="notranslate">
                more
              </font>

              <font v-if="currentCountryNotEnglish">
                - more
              </font>
            </v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </div>

    <div id="google_translate_element2">
      <slot></slot>
    </div>

    <div
      v-if="
        !currentCountry ||
          (currentCountry &&
            currentCountry.code &&
            currentCountry.code != 'en|en')
      "
    >
      <v-btn @click="resetTranslator()">
        <font translate="no" class="notranslate">
          Reset
        </font>

        <font v-if="currentCountryNotEnglish">
          - Reset
        </font>

        <v-icon>
          mdi-close-circle
        </v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
const codeToNativeAndName = require("./translator/codeToNativeAndName");
export default {
  name: "mvTranslator",
  props: {
    countries: {
      type: Array,
      default() {
        return this.defaultCountries;
      }
    }
  },
  data() {
    return {
      useFullListFromToNativeList: true,
      isOpen: false,
      showFullList: false,
      items: [
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me" },
        { title: "Click Me 2" }
      ],
      currentCountry: {
        code: "en|en",
        title: "English"
      },
      defaultCountry: {
        code: "en|en",
        title: "English"
      },

      defaultCountries: [
        {
          code: "en|en",
          title: "English"
        },
        {
          code: "en|af",
          title: "Afrikaans"
        },
        {
          code: "en|sq",
          title: "Albanian"
        },
        {
          code: "en|ar",
          title: "Arabic"
        },
        {
          code: "en|hy",
          title: "Armenian"
        },
        {
          code: "en|az",
          title: "Azerbaijani"
        },
        {
          code: "en|eu",
          title: "Basque"
        },
        {
          code: "en|be",
          title: "Belarusian"
        },
        {
          code: "en|bg",
          title: "Bulgarian"
        },
        {
          code: "en|ca",
          title: "Catalan"
        },
        {
          code: "en|zh-CN",
          title: "Chinese (Simplified)"
        },
        {
          code: "en|zh-TW",
          title: "Chinese (Traditional)"
        },
        {
          code: "en|hr",
          title: "Croatian"
        },
        {
          code: "en|cs",
          title: "Czech"
        },

        {
          code: "en|da",
          title: "Danish"
        },
        {
          code: "en|nl",
          title: "Dutch"
        },
        {
          code: "en|et",
          title: "Estonian"
        },
        {
          code: "en|tl",
          title: "Filipino"
        },
        {
          code: "en|fi",
          title: "Finnish"
        },
        {
          code: "en|fr",
          title: "French"
        },

        {
          code: "en|de",
          title: "German"
        },
        {
          code: "en|el",
          title: "Greek"
        },
        {
          code: "en|hu",
          title: "Hungarian"
        },
        {
          code: "en|hi",
          title: "Indian (Hindi)"
        },
        {
          code: "en|id",
          title: "Indonesian"
        },
        {
          code: "en|ga",
          title: "Irish"
        },
        {
          code: "en|it",
          title: "Italian"
        },
        {
          code: "en|ja",
          title: "Japanese"
        },
        {
          code: "en|ko",
          title: "Korean"
        },
        {
          code: "en|lt",
          title: "Lithuanian"
        },
        {
          code: "en|ms",
          title: "Malay"
        },
        {
          code: "en|no",
          title: "Norwegian"
        },
        {
          code: "en|pl",
          title: "Polish"
        },

        {
          code: "en|pt",
          title: "Portuguese"
        },
        {
          code: '"en|ro',
          title: "Romanian"
        },
        {
          code: "en|ru",
          title: "Russian"
        },
        {
          code: "en|es",
          title: "Spanish"
        },
        {
          code: "en|sv",
          title: "Swedish"
        },
        {
          code: "en|th",
          title: "Thai"
        },
        {
          code: "en|tr",
          title: "Turkish"
        },
        {
          code: "en|uk",
          title: "Ukrainian"
        }
      ]
    };
  },
  computed: {
    hasClickListener() {
      return Object.keys(this.$listeners).includes("on-country-click");
    },

    currentCountryNotEnglish() {
      return (
        this.currentCountry &&
        this.currentCountry.code &&
        this.currentCountry.code != "en|en"
      );
    },

    codeToNativeLookup() {
      let lookup = {};

      codeToNativeAndName.forEach(obj => {
        lookup[obj.code] = obj.native;
      });

      return lookup;
    },

    showFullListAct() {
      if (this.showFullList === true) {
        return true;
      } else if (
        this.currentCountryNotEnglish &&
        this.currentCountry &&
        this.currentCountry.code
      ) {
        //has current code, but not english
        //is code in cut down list;
        // countries : defaultCountries

        let needle = this.currentCountry.code;
        let found;
        try {
          found = this.countries.filter(row => row.code == needle)[0];
        } catch (error) {
          //do nothing;
        }
        //console.log({ found });

        if (found) {
          return false;
        } else {
          return true;
        }
      } else {
        return false;
      }
    }
  },
  mounted() {
    if (this.useFullListFromToNativeList) {
      this.defaultCountries = codeToNativeAndName.map(row => {
        return { code: `en|${row.code}`, title: row.language };
      });
    }

    function readCookie(name) {
      var c = document.cookie.split("; "),
        cookies = {},
        i,
        C;

      for (i = c.length - 1; i >= 0; i--) {
        C = c[i].split("=");
        cookies[C[0]] = C[1];
      }

      return cookies[name];
    }

    //console.log(readCookie("googtrans"));
    setTimeout(() => {
      try {
        let cookieValue = readCookie("googtrans");

        cookieValue = cookieValue
          .split("en")[1]
          .split("/")[1]
          .trim();

        if (cookieValue) {
          this.doTranslation("en|" + cookieValue);
        }
      } catch (error) {
        //do nothing;
      }
    }, 200);
  },
  methods: {
    getNativeName(obj) {
      try {
        let code = obj.code.split("|")[1];
        if (obj && obj.code && code && this.codeToNativeLookup[code]) {
          return this.codeToNativeLookup[code];
        } else {
          return false;
        }
      } catch (error) {
        //console.log({ error });
        return false;
      }
    },
    showMoreItems() {
      setTimeout(() => {
        this.isOpen = true;
        this.showFullList = true;
      }, 300);
    },
    resetTranslator() {
      this.currentCountry = this.defaultCountry;
      window.doGTranslate(this.currentCountry.code);
    },
    doTranslation(code) {
      this.isOpen = false;
      this.showFullList = false;

      //if (this.currentCode != code) {
      // ensure the code doesn't break on server side
      if (typeof window !== "undefined") {
        window.doGTranslate(code);
        if (this.hasClickListener) {
          this.$emit("on-country-click");
        }
      }

      try {
        this.currentCountry = this.defaultCountries.filter(
          row => row.code == code
        )[0];
      } catch (error) {
        //do nothing;
      }
      //}
    },
    checkIfCountryIsAvailableInDefaults(countryObject) {
      return this.defaultCountries.some(
        country =>
          country.code.toLowerCase() === countryObject.code.toLowerCase() &&
          country.title.toLowerCase() === countryObject.title.toLowerCase()
      );
    }
  }
};
</script>

<style>
.menuList {
  max-height: calc(100vh - 116px);
}

.menuList div {
  background: white;
}

/* gets rid of the banner at the top of the page */
body {
  top: 0 !important;
}
/* get rids of the banner at the bottom of the web page */
.skiptranslate {
  display: none !important;
}
</style>
