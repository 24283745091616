<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      :width="$vuetify.breakpoint.smAndDown ? '95%' : 900"
    >
      <template v-slot:activator="{ on, attrs }">
        <span v-bind="attrs" v-on="on" class="secondary--text  "> About </span>
      </template>

      <v-card>
        <v-card-title
          :style="{
            background: appSettings.menuBarColor
          }"
          class="  xlighten-2 secondary--text text-center justify-center siteHeadingText"
        >
          {{ title }}
        </v-card-title>
        <div :class="{ mobileSizeDialog: $vuetify.breakpoint.xsOnly }">
          <v-row no-gutters class="contentWrapper  secondary--text " style="">
            <v-col cols="12" md="6" class="pt-3 px-4">
              <center>
                <img
                  src="assets/landingPages/heroImage.jpg"
                  style="max-width:100%"
                />

                <v-img src="assets/landingPages/logo.png" max-width="300" />
              </center>
            </v-col>
            <v-col cols="12" md="6" class="pt-3 px-4 welcomeText">
              <center>
                <p>
                  Welcome to Sovereign Hill.
                </p>
                <p>
                  Explore all that Sovereign Hill has to offer with this
                  interactive map to plan your visit and to find your way around
                  the site on your mobile device.
                </p>
                <p>
                  Go on a virtual tour with information, videos, and links both
                  within the map and on the side bar.
                </p>
                <p>
                  The map has five layers which you can turn on or off depending
                  on your needs and interests.
                  <img
                    src="assets/landingPages/gettingstarted/layers.png"
                    xxmax-width="100%"
                  />
                </p>
                <p>
                  Access the layers with this button in the top right of your
                  screen:
                  <img
                    src="assets/landingPages/gettingstarted/layerButton.png"
                    xxmax-width="100%"
                  />
                </p>
                <p>
                  You can also view the map legend by clicking on this button:
                  <img
                    src="assets/landingPages/gettingstarted/legendButton.png"
                    xxmax-width="100%"
                  />
                </p>
                <p>
                  Click on this button to find your location when you are at
                  Sovereign Hill:
                  <img
                    src="assets/landingPages/gettingstarted/locationButton.png"
                    xxmax-width="100%"
                  />
                </p>
                <p>
                  Enjoy your visit!
                </p>
              </center>
            </v-col>
          </v-row>

          <v-divider></v-divider>
        </div>

        <v-card-actions>
          <v-spacer></v-spacer>
          <a
            href="http://maptivate.com.au/"
            target="_blank"
            style="text-decoration: none!important;"
          >
            <v-btn
              v-if="false"
              :color="appSettings.dialogActionButtonColor"
              text
            >
              More Information
              <font v-if="!$vuetify.breakpoint.smAndDown">About Maptivate</font>
            </v-btn>
          </a>
          <v-btn
            :color="appSettings.dialogActionButtonColor"
            text
            @click="dialog = false"
          >
            close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { eventBus } from "../../main";
import { mapState } from "vuex";

export default {
  name: "AboutModal",

  data() {
    return {
      dialog: true,
      title: "Welcome"
    };
  },
  computed: {
    ...mapState({
      appSettings: state => state.global.appSettings
    })
  },
  created() {
    eventBus.$on("loadAboutDialog", () => {
      this.dialog = true;
    });
  },
  mounted() {}
};
</script>

<style>
.welcomeText img {
  display: block;
}

.mobileSizeDialog {
  max-height: calc(100vh - 150px);
  overflow-y: auto;
  overflow-x: hidden;
}
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 100%;
}
.contentWrapper {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

@media only screen and (max-width: 960px) {
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 98%;
  }

  .contentWrapper {
    xmax-height: calc(100vh - 180px);
  }
}

@media only screen and (max-width: 760px) {
  .contentWrapper {
    max-height: calc(100vh - 240px);
  }
}
</style>
