//import { _ } from "core-js";

const getters = {};

const actions = {
  filters_controlsUpdateLayers({ commit }, value) {
    commit("UPDATE_LAYERS", value);
    let hhwCount = value.filter(row => row.includes("_hhw"));
    let markerCount = value.filter(row => state.hardCodedMarkerLayers[row]);

    if (hhwCount.length !== 3 && state.hhwModeValue === true) {
      commit("SET_hhwModeValue", false);
    } else if (hhwCount.length === 3 && markerCount.length === 0) {
      commit("SET_hhwModeValue", true);
    }
  },

  filters_updateData({ commit }, wpData) {
    const categoryLookup = wpData.categoryLookup;

    const categoryArray = _.values(categoryLookup);

    let bools = categoryArray.reduce(function(map, obj) {
      map[obj.key] = true;
      return map;
    }, {});

    commit("SET_selected", { bools: bools });
    commit("SET_categoryLookup", categoryLookup);
    commit("SET_defaultLayers", _.keys(categoryLookup));
    commit("SET_layers", _.keys(categoryLookup));
    commit("SET_setup", { bools: [...categoryArray] });
  },

  filters_resetLayers({ state, commit }) {
    //commit("UPDATE_LAYERS", state.defaultLayers);

    let defaultLayers = state.defaultLayers;
    defaultLayers = defaultLayers.filter(key => {
      let object = state.categoryLookup[key];
      if (object.section_type != "main_marker") {
        return true;
      } else if (key.includes("_hhw") === state.hhwModeValue) {
        return true;
      } else {
        return false;
      }
    });

    commit("UPDATE_LAYERS", defaultLayers);
  },

  filters_changeMode({ commit }, newValue) {
    commit("SET_hhwModeValue", newValue);
  }
};

const mutations = {
  UPDATE_LAYERS(state, value) {
    let oldValue = JSON.parse(JSON.stringify(state.layers));
    if (oldValue !== JSON.parse(JSON.stringify(value))) {
      state.layers = JSON.parse(JSON.stringify(value));
    }
  },

  SET_selected(state, newValue) {
    state.selected = newValue;
  },
  SET_defaultLayers(state, newValue) {
    state.defaultLayers = newValue;
  },
  SET_layers(state, newValue) {
    state.layers = newValue;
  },
  SET_setup(state, newValue) {
    state.setup = newValue;
  },
  SET_categoryLookup(state, newValue) {
    state.categoryLookup = newValue;
  },
  SET_hhwModeValue(state, newValue) {
    state.hhwModeValue = newValue;
  }
};

import { _ } from "core-js";

const state = {
  alwasyOnLayers: {
    "base-map-emergency-info": true,
    "visitor-services": true
  },
  hardCodedMarkerLayers: {
    "food-and-shopping": true,
    "points-of-interest": true,
    "wadawurrung-stories": true
  },

  hhwModeValue: false,
  categoryLookup: {},
  selectable: {},
  selected: {
    bools: {}
    //{ "Prospective Families": true, Wayfinding: true, History: true }
  },

  defaultLayers: [], //["History", "Prospective Families", "Wayfinding"],
  layers: [], //["History", "Prospective Families", "Wayfinding"],
  setup: {
    bools: []
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
